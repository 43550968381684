@charset 'UTF-8';
@import "swiper";
/*----------------------------------------------------------------------

Variable

----------------------------------------------------------------------*/
$breakpoints: (
  "sm": "screen and (min-width: 576px)",
  "md": "screen and (min-width: 768px)",
  "lg": "screen and (min-width: 992px)",
  "xl": "screen and (min-width: 1200px)"
) !default;

$c-w: #ffffff!important;
$c-g: #e3dfd8!important;
$c-o: #c6b489!important;
$c-b: #231815!important;

$easeInQuad: cubic-bezier(.55, .085, .68, .53);
$easeOutQuad: cubic-bezier(.25, .46, .45, .94);
$easeInOutQuad: cubic-bezier(.455, .03, .515, .955);

$ff-sans: -apple-system, BlinkMacSystemFont, Roboto, "Hiragino Kaku Gothic ProN", Meiryo, sans-serif;
$ff-serif: "Noto Serif JP", "Hiragino Mincho ProN", "HG明朝E", serif;

$fw-300: 300!important;
$fw-500: 500!important;

$hh-sm: 52px;
$hh-md: 63px;
$hh-lg: 105px;

$fh-md: 1.5rem;
$fh-lg: 3rem;


/*----------------------------------------------------------------------

Mixin

----------------------------------------------------------------------*/
@mixin mq($breakpoint: md) {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
  }
}

@mixin bg(){
  background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

@mixin ps($position: absolute, $top: 0, $bottom: 0, $left: 0, $right: 0){
  position: $position;
  top: $top;
  bottom: $bottom;
  left: $left;
  right: $right;
}


/*----------------------------------------------------------------------

IconFont

----------------------------------------------------------------------*/
@font-face {
  font-family: "icomoon";
	font-style: normal;
	font-weight: normal;
  src: url("font/icomoon.woff?wn4mxf") format("woff"),
       url("font/icomoon.ttf?wn4mxf") format("truetype");
}
[class^="icon-"], [class*=" icon-"] {
	font-family: "icomoon" !important;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	text-transform: none;
	text-decoration: inherit;
	speak: none;
}
.icon-line:before {
  content: "\e900";
}
.icon-instagram:before {
  content: "\e901";
}
.icon-twitter:before {
  content: "\e902";
}
.icon-facebook:before {
  content: "\e903";
}
.icon-pdf:before {
  content: "\e904";
}
.icon-fax:before {
  content: "\e905";
}
.icon-email:before {
  content: "\e906";
}
.icon-phone:before {
  content: "\e907";
}


/*----------------------------------------------------------------------

Setting

----------------------------------------------------------------------*/
* {
  font-weight: $fw-300;
}
html {
  font-size: 14px;
}
body {
  color: $c-b;
	font-family: $ff-sans;
  font-feature-settings: "palt" 1;
	overflow-wrap: break-word;
	word-wrap: break-word;
  &::before {
    @include mq() {
      background-color: $c-w;
      width: 1.5rem;
      z-index: 99;
      content: "";
      @include ps(fixed, 0, 0, 0, auto);
    }
    @include mq(lg) {
      width: 3rem;
    }
  }
  &::after {
    @include mq() {
      background-color: $c-w;
      width: 1.5rem;
      z-index: 99;
      content: "";
      @include ps(fixed, 0, 0, auto, 0);
    }
    @include mq(lg) {
      width: 3rem;
    }
  }
  @include mq() {
    padding-bottom: $fh-md;
  }
  @include mq(lg) {
    padding-bottom: $fh-lg;
  }
}
h1, h2, h3, h4, h5, h6 {
  font-family: $ff-serif;
  line-height: 1.4;
}
p {
  line-height: 2;
  text-align: justify;
}
a, a:before, a:after {
  font-family: $ff-serif;
  transition: all .3s $easeInOutQuad 0s;
}
a:hover {
  text-decoration: none;
}
time {
  font-family: $ff-sans;
  font-size: .75rem;
  @include mq(sm) {
    font-size: 1rem;
  }
}
small {
  font-size: .75rem;
}
figure {
  margin: 0;
}
img {
	display: block;
	width: 100%;
	height: auto;
}
#svg {
  display: none;
}

//Text
.font-weight-normal {
  font-weight: $fw-300;
}
.font-weight-bold {
  font-weight: $fw-500;
}
.text-warning {
  color: $c-o;
}
.text-light {
  color: $c-g;
}
.text-dark {
  color: $c-b;
}

//Background
.bg-warning {
  background-color: $c-o;
}
.bg-light {
  background-color: $c-g;
}
.bg-dark {
  background-color: $c-b;
  color: $c-g;
}

//Border
.border-warning {
  border-color: $c-o;
}
.border-light {
  border-color: $c-g;
}
.border-dark {
  border-color: $c-b;
}

//Buttons
.btn {
  border-radius: 0;
  border: 0;
  line-height: 1;
  width: 160px;
  transition: all .3s $easeInOutQuad 0s;
  @include mq() {
    width: 200px;
  }
  &-link {
    color: $c-b;
  }
  &-warning {
    background-color: $c-o;
    color: $c-w;
    &:hover {
      background-color: $c-g;
      color: $c-b;
    }
  }
  &-light {
    background-color: $c-g;
    color: $c-b;
    &:hover {
      background-color: $c-o;
      color: $c-w;
    }
  }
  &-dark {
    background-color: $c-b;
    color: $c-o;
    &:hover {
      background-color: $c-o;
      color: $c-w;
    }
  }
  &-white {
    background-color: $c-w;
    color: $c-b;
    &:hover {
      color: $c-o;
    }
  }
}
.arrow {
  background-color: $c-b;
  border: none;
  overflow: hidden;
  position: relative;
  width: 52px;
  height: 52px;
  transition: all .3s $easeInOutQuad 0s;
  &:hover {
    background-color: $c-o;
    &::before {
      border-color: $c-w;
    }
  }
  &::before {
    content: "";
    display: inline-block;
    width: 12.8px;
    height: 12.8px;
    margin: auto;
    transform: rotate(45deg);
    transition: all .3s $easeInOutQuad 0s;
    @include ps(absolute, 0, 0, 0, 0);
  }
  &-up {
    &::before {
      border-top: 2px solid $c-o;
      border-left: 2px solid $c-o;
      top: 6px;
    }
  }
  &-down {
    &::before {
      border-bottom: 2px solid $c-o;
      border-right: 2px solid $c-o;
      bottom: 6px;
    }
  }
  &-left {
    &::before {
      border-bottom: 2px solid $c-o;
      border-left: 2px solid $c-o;
      left: 6px;
    }
  }
  &-right {
    &::before {
      border-top: 2px solid $c-o;
      border-right: 2px solid $c-o;
      right: 6px;
    }
  }
}

//Add Class
.title {
  h1, h2 {
    font-size: .75rem;
    font-weight: $fw-500;
    @include mq() {
      font-size: 1rem;
    }
  }
  h3 {
    color: $c-o;
    font-size: 1.25rem;
    letter-spacing: .2em;
    @include mq() {
      font-size: 1.5rem;
    }
  }
  .t-lg {
    font-size: 1.5rem;
    @include mq() {
      font-size: 1.75rem;
    }
  }
}


/*----------------------------------------------------------------------

Header Style

----------------------------------------------------------------------*/
#h-logo {
  padding-bottom: $hh-sm;
  @include mq() {
    padding-bottom: $hh-md;
  }
  @include mq(lg) {
    padding-bottom: $hh-lg;
  }
  a {
    color: $c-o;
    font-size: 1.5rem;
    display: inline-block;
    &:hover {
      opacity: .6;
    }
  }
}

//Navigation
#h-nav {
  z-index: 1030;
  @include ps(absolute, auto, 0, 0, 0);
  &.h-navfixed {
    position: fixed;
    top: 0;
    bottom: auto;
    @include mq() {
      left: 1.5rem;
      right: 1.5rem;
    }
    @include mq(lg) {
      left: 3rem;
      right: 3rem;
    }
  }
}

//NavigationTel
#h-n-tel {
  a {
    color: $c-b;
    height: 52px;
    &:hover {
      color: $c-o;
    }
    .h-n-t-icon {
      font-size: 32px;
      @include mq() {
        font-size: 1.75rem;
      }
      @include mq(xl) {
        font-size: 32px;
      }
    }
    .h-n-t-text {
      font-size: 1.5rem;
      font-weight: $fw-500;
      letter-spacing: .08em;
      line-height: .8;
      @include mq(sm) {
        font-size: 2rem;
        font-weight: $fw-300;
        line-height: .6;
      }
      @include mq() {
        font-size: 1.25rem;
        font-weight: $fw-500;
        line-height: 1;
      }
      @include mq(xl) {
        font-size: 2rem;
        font-weight: $fw-300;
        line-height: .6;
      }
      small {
        font-family: $ff-sans;
      }
    }
  }
}

//NavigationContact
#h-n-buttons {
  list-style: none;
  li{
    .h-n-b-link {
      border-right: 1px solid #fff;
      line-height: 1;
      width: 52px;
      height: 52px;
      i {
        font-size: 32px;
        width: 32px;
        height: 32px;
        margin: auto;
        @include ps(absolute, 0, 0, 0, 0);
      }
    }
  }
  &.h-n-buttonsline {
    width: 100%;
    @include mq(sm) {
      width: auto;
    }
    li {
      width: 25%;
      @include mq(sm) {
        width: auto;
      }
      .h-n-b-link, #h-n-b-toggler {
        width: 100%;
        @include mq(sm) {
          width: 52px;
        }
      }
      .h-n-b-link {
        @include mq(sm) {
          background-color: $c-b;
          color: $c-o;
          &:hover {
            background-color: $c-o;
            color: $c-w;
          }
        }
      }
    }
  }
}

//NavigationToggle
#h-n-b-toggler {
	background-color: $c-b;
  border: none;
  cursor: pointer;
  display: block;
	overflow: hidden;
	position: relative;
	width: 52px;
	height: 52px;
  &.active {
    span {
    	animation: closeBounce .6s $easeInOutQuad 0s both;
      &::before, &::after {
        top: calc(50% - 2px);
      	transform: rotate(90deg);
      }
      &::after {
        opacity: 0;
      }
    }
  }
  span {
    background-color: $c-o;
  	content: "";
  	display: block;
  	width: 32px;
  	height: 2px;
    margin: auto;
  	animation: openBounce .6s $easeInOutQuad 0s both;
    @include ps(absolute, 0, 0, 0, 0);
    &::before, &::after {
      background-color: $c-o;
    	content: "";
    	display: block;
    	position: absolute;
      left: 0;
    	width: 32px;
    	height: 2px;
    	transition: all .3s $easeInOutQuad 0s;
    }
    &::before {
      top: calc(50% - 9px);
    }
    &::after {
      top: calc(50% + 7px);
    }
  }
}

//NavigationList
#h-n-list {
  background-color: rgba(35, 24, 21, .9);
  display: none;
  overflow-y: auto;
  @include ps(fixed, $hh-sm, 0, 0, 0);
  @include mq() {
    background-color: $c-w;
    flex-grow: 0;
    display: block;
    overflow-y: visible;
    @include ps(relative, auto, auto, auto, auto);
  }
  .navbar-nav {
    .nav-item {
      opacity: 0;
      transform: translateY(32px);
      transition: all .3s $easeOutQuad 0s;
      @include mq() {
        opacity: 1;
        transform: translateY(0);
      }
      &.active {
        opacity: 1;
        transform: translateY(0);
      }
      &:hover {
        .dropdown-menu {
          opacity: 1;
          visibility: visible;
          max-height: 400px;
        }
      }
      .nav-link {
        color: $c-o;
        font-weight: $fw-500;
        letter-spacing: 0;
        &:hover, &.active {
          background-color: $c-o;
          color: $c-w;
        }
        @include mq() {
          color: $c-b;
          &:hover, &.active {
            background-color: $c-w;
            color: $c-o;
          }
        }
      }
      .dropdown-menu {
        background-color: transparent;
        border: 0;
        border-radius: 0;
        opacity: 0;
        display: block;
        visibility: hidden;
        overflow: hidden;
        max-height: 0;
        margin-top: 0;
        transition: all .3s $easeOutQuad 0s;
        .dropdown-item {
          background-color: $c-b;
          color: $c-o;
          font-weight: $fw-500;
          &:hover, &.active {
            background-color: $c-o;
            color: $c-w;
          }
          @include mq() {
            margin-top: 1px;
          }
        }
      }
    }
  }
}

//Intro
#h-intro {
  padding-bottom: 160px;
  @include mq() {
    padding-bottom: 80px;
  }
  #h-i-catch {
    overflow: hidden;
  	z-index: 1;
    @include ps(absolute, auto, 0, 1.5rem, 1.5rem);
    @include mq() {
      left: 0;
      right: auto;
      width: 50%;
    }
    @include mq(lg) {
      width: 33.33333%;
    }
  }
  #h-i-image {
  	overflow: hidden;
    height: 480px;
    @include mq() {
      height: 640px;
    }
    @include mq(lg) {
      height: 720px;
    }
    #h-i-i-plx {
      @include bg();
      @include ps(absolute, -160px, 0, 0, 0);
      @include mq() {
        top: -140px;
      }
      @include mq(lg) {
        top: -120px;
      }
    }
  }
}


/*----------------------------------------------------------------------

Main Style

----------------------------------------------------------------------*/
#main {}

//Events
#events {
  #e-list {
    .btn-link {
      &:hover {
        color: $c-o;
      }
      h4 {
        font-family: $ff-sans;
        font-size: 1rem;
        @include mq(sm) {
          border-left: 1px solid $c-g;
        }
      }
    }
  }
}

//Service
#service {
  .container-fluid {
    padding-left: 1rem;
    padding-right: 1rem;
    @include mq(sm) {
      padding-left: .75rem;
      padding-right: .75rem;
    }
    @include mq(lg) {
      padding-left: 2.25rem;
      padding-right: 2.25rem;
    }
    #s-list {
      section {
        padding-left: .5rem;
        padding-right: .5rem;
        @include mq(sm) {
          padding-left: .75rem;
          padding-right: .75rem;
        }
        a {
          color: $c-b;
          &:hover {
            color: $c-o;
            .s-l-image {
              .s-l-i-plx {
                transform: scale(1.16);
              }
            }
          }
          .s-l-image {
            overflow: hidden;
            padding-top: 61.80469%;
            .s-l-i-plx {
              transition: all .3s $easeInOutQuad 0s;
              @include bg();
              @include ps(absolute, -1px, -1px, -1px, -1px);
            }
          }
          .s-l-title {
            font-size: .75rem;
            font-weight: $fw-500;
            line-height: 1;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            @include ps(absolute, auto, 0, 20%, 0);
            @include mq(sm) {
              font-size: 1rem;
            }
            @include mq() {
              font-size: 1.25rem;
              font-weight: $fw-300;
            }
            @include mq(lg) {
              font-size: 1.5rem;
            }
          }
        }
      }
    }
  }
}

//Instructor
#instructor {
  .title {
    text-align: center;
    @include mq() {
      text-align: left;
      z-index: 11;
      @include ps(absolute, 3rem, auto, 50%, 0);
    }
  }
  #i-list {
    margin-bottom: -1px;
    .swiper-slide {
      .i-l-image {
        overflow: hidden;
        padding-top: 61.80469%;
        @include mq() {
          padding-top: 0;
        }
        .i-l-i-plx {
          @include bg();
          @include ps(absolute, -220px, 0, 0, 0);
          @include mq() {
            top: -180px;
          }
        }
      }
      .i-l-profile {
        z-index: 1;
        margin-top: -0.9rem;
        padding: 0 0 3rem;
        @include mq() {
          margin-top: 0;
          padding: 13rem 1.5rem 6rem;
        }
        @include mq(lg) {
          padding: 13rem 3rem 6rem;
        }
        .i-l-p-title {
          color: $c-w;
          font-size: 1.5rem;
          @include mq() {
            font-size: 1.75rem;
          }
        }
      }
    }
  }
  .swiper-pagination {
    font-family: $ff-serif;
    font-size: 3.5rem;
    top: 9.4rem;
    bottom: auto;
    right: 0;
    width: auto;
    @include mq() {
      top: 5.2rem;
    }
  }
  .swiper-buttons {
    z-index: 10;
    padding-top: calc(61.80469% - 25px);
    @include ps(absolute, 12.2rem, auto, 1.5rem, 1.5rem);
    @include mq() {
      top: 0;
      bottom: 0;
      left: 0;
      right: 50%;
      padding-top: 0;
    }
    .swiper-prev {
      @include ps(absolute, auto, -26px, auto, 52px);
      @include mq() {
        bottom: 0;
      }
    }
    .swiper-next {
      @include ps(absolute, auto, -26px, auto, 0);
      @include mq() {
        bottom: 0;
      }
    }
    .swiper-button-disabled {
      opacity: .6;
      pointer-events: none;
    }
  }
}

//Banner
#banner {
  .container {
    list-style: none;
    padding: .75rem .75rem;
    @include mq() {
      padding: 5.25rem 2.25rem;
    }
    @include mq(lg) {
      padding: 5.25rem 2.25rem;
    }
    li {
      padding: .75rem;
      a {
        &:hover {
          opacity: .6;
        }
        img {
          max-width: 100%;
          width: auto;
        }
      }
    }
  }
}

//News
#news {
  .container-fluid {
    padding-left: 1rem;
    padding-right: 1rem;
    @include mq(sm) {
      padding-left: .75rem;
      padding-right: .75rem;
    }
    @include mq(lg) {
      padding-left: 2.25rem;
      padding-right: 2.25rem;
    }
    #n-list {
      article {
        padding-left: 0;
        padding-right: 0;
        @include mq(sm) {
          padding-left: .75rem;
          padding-right: .75rem;
        }
        a {
          color: $c-b;
          @include mq(sm) {
            height: 100%;
          }
          &:hover {
            color: $c-o;
            .n-l-image {
              figure {
                transform: scale(1.16);
              }
            }
          }
          .n-l-image {
            width: 33.33333%;
            padding-left: .5rem;
            padding-right: .5rem;
            @include mq(sm) {
              width: 100%;
              padding-left: 0;
              padding-right: 0;
            }
            .n-l-i-con {
              overflow: hidden;
              padding-top: 61.80469%;
              figure {
                transition: all .3s $easeInOutQuad 0s;
                @include bg();
                @include ps(absolute, -1px, -1px, -1px, -1px);
              }
            }
          }
          .n-l-text {
            width: 66.66666%;
            padding-left: .5rem;
            padding-right: .5rem;
            @include mq(sm) {
              width: 100%;
              padding-left: 0;
              padding-right: 0;
            }
            .n-l-t-cat {
              background-color: $c-o;
              color: $c-w;
              font-family: $ff-sans;
              font-size: .75rem;
              line-height: 1;
            }
            h4 {
              font-family: $ff-sans;
              font-size: 1rem;
              font-weight: $fw-500;
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
              @include mq(sm) {
                white-space: normal;
                overflow:  inherit;
              }
            }
          }
        }
      }
    }
  }
}

//Contact
#contact {
  .container {
    padding-left: 1rem;
    padding-right: 1rem;
    @include mq(sm) {
      padding-left: .75rem;
      padding-right: .75rem;
    }
    @include mq() {
      width: 720px;
    }
    @include mq(lg) {
      padding-left: 2.25rem;
      padding-right: 2.25rem;
    }
    #c-text {
      padding-left: .5rem;
      padding-right: .5rem;
      @include mq(sm) {
        padding-left: .75rem;
        padding-right: .75rem;
      }
    }
    #c-list {
      list-style: none;
      padding-left: 0;
      padding-right: 0;
      li {
        padding-left: .5rem;
        padding-right: .5rem;
        @include mq(sm) {
          padding-left: .75rem;
          padding-right: .75rem;
        }
        .btn {
          line-height: 1.2;
          width: 100%;
          @include mq(sm) {
            font-size: 1.25rem;
            line-height: .8;
            text-align: left;
          }
          @include mq() {
            font-size: 2rem;
          }
          .c-l-icon {
            font-size: 3rem;
            line-height: 1;
            display: block;
            @include mq() {
              font-size: 3.5rem;
            }
          }
          .c-l-text {
            letter-spacing: .08em;
            .c-l-t-mail {
              letter-spacing: 0;
            }
            small {
              font-family: $ff-sans;
            }
          }
        }
      }
    }
  }
}


/*----------------------------------------------------------------------

Footer Style

----------------------------------------------------------------------*/
#footer {
  @include mq() {
    z-index: 1030;
    @include ps(fixed, auto, 0, 0, 0);
  }
  .container {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    @include mq(lg) {
      padding-left: 3rem;
      padding-right: 3rem;
    }
    p {
      line-height: 1.5rem;
      @include mq(lg) {
        line-height: 3rem;
      }
    }
  }
  #f-totop {
    cursor: pointer;
    display: none;
    @include mq() {
      display: block;
      @include ps(absolute, auto, 100%, auto, 1.5rem);
    }
    @include mq(lg) {
      right: 3rem;
    }
  }
}


/*----------------------------------------------------------------------

Animation

----------------------------------------------------------------------*/
@keyframes openBounce {
	  0% { transform: rotate(135deg);}
	 40% { transform: rotate(-16deg);}
	 80% { transform: rotate(8deg);}
	100% { transform: rotate(0deg);}
}
@keyframes closeBounce {
	  0% { transform: rotate(0deg);}
	 40% { transform: rotate(151deg);}
 	 80% { transform: rotate(128deg);}
	100% { transform: rotate(135deg);}
}

//Intro
#h-i-catch {
  opacity: 0;
  transform: translateY(32px);
  transition: all .39s $easeOutQuad 0s;
  &.anima {
    opacity: 1;
    transform: translateY(0);
    #h-i-c-title, #h-i-c-text, #h-i-c-link {
      opacity: 1;
      transform: translateY(0);
    }
  }
  #h-i-c-title, #h-i-c-text, #h-i-c-link {
    opacity: 0;
    transform: translateY(32px);
  }
  #h-i-c-title {
    transition: all .39s $easeOutQuad .3s;
  }
  #h-i-c-text {
    transition: all .39s $easeOutQuad .48s;
  }
  #h-i-c-link {
    transition: all .39s $easeOutQuad .66s;
  }
}

//Events
#events {
  opacity: 0;
  transform: translateY(32px);
  transition: all .39s $easeOutQuad 0s;
  &.anima {
    opacity: 1;
    transform: translateY(0);
    .title, #e-list {
      opacity: 1;
      transform: translateY(0);
    }
  }
  .title, #e-list {
    opacity: 0;
    transform: translateY(32px);
  }
  .title {
    transition: all .39s $easeOutQuad .3s;
  }
  #e-list {
    transition: all .39s $easeOutQuad .48s;
  }
}

//Service
#service {
  opacity: 0;
  transform: translateY(32px);
  transition: all .39s $easeOutQuad 0s;
  &.anima {
    opacity: 1;
    transform: translateY(0);
    .title {
      opacity: 1;
      transform: translateY(0);
    }
  }
  .title, section {
    opacity: 0;
    transform: translateY(32px);
  }
  .title {
    transition: all .39s $easeOutQuad .3s;
  }
  section {
    transition: all .39s $easeOutQuad .48s;
    &.anima {
      opacity: 1;
      transform: translateY(0);
    }
  }
}

//Instructor
#instructor {
  opacity: 0;
  transform: translateY(32px);
  transition: all .39s $easeOutQuad 0s;
  &.anima {
    opacity: 1;
    transform: translateY(0);
    .title {
      opacity: 1;
      transform: translateY(0);
    }
    .swiper-slide {
      &.swiper-slide-active {
        .i-l-p-title, .i-l-p-text, .i-l-p-link {
          opacity: 1;
          transform: translateY(0);
        }
      }
    }
  }
  .title {
    opacity: 0;
    transform: translateY(32px);
    transition: all .39s $easeOutQuad .3s;
  }
  .swiper-slide {
    .i-l-p-title, .i-l-p-text, .i-l-p-link {
      opacity: 0;
      transform: translateY(32px);
    }
    .i-l-p-title {
      transition: all .39s $easeOutQuad .48s;
    }
    .i-l-p-text {
      transition: all .39s $easeOutQuad .66s;
    }
    .i-l-p-link {
      transition: all .39s $easeOutQuad .84s;
    }
  }
}

//Banner
#banner {
  opacity: 0;
  transform: translateY(32px);
  transition: all .39s $easeOutQuad 0s;
  &.anima {
    opacity: 1;
    transform: translateY(0);
  }
  li {
    opacity: 0;
    transform: translateY(32px);
    transition: all .39s $easeOutQuad .3s;
    &.anima {
      opacity: 1;
      transform: translateY(0);
    }
  }
}

//News
#news {
  opacity: 0;
  transform: translateY(32px);
  transition: all .39s $easeOutQuad 0s;
  &.anima {
    opacity: 1;
    transform: translateY(0);
    .title {
      opacity: 1;
      transform: translateY(0);
    }
  }
  .title, article {
    opacity: 0;
    transform: translateY(32px);
  }
  .title {
    transition: all .39s $easeOutQuad .3s;
  }
  article {
    transition: all .39s $easeOutQuad .48s;
    &.anima {
      opacity: 1;
      transform: translateY(0);
    }
  }
}

//Contact
#contact {
  opacity: 0;
  transform: translateY(32px);
  transition: all .39s $easeOutQuad 0s;
  &.anima {
    opacity: 1;
    transform: translateY(0);
    .title, #c-text, #c-list {
      opacity: 1;
      transform: translateY(0);
    }
  }
  .title, #c-text, #c-list {
    opacity: 0;
    transform: translateY(32px);
  }
  .title {
    transition: all .39s $easeOutQuad .3s;
  }
  #c-text {
    transition: all .39s $easeOutQuad .48s;
  }
  #c-list {
    transition: all .39s $easeOutQuad .66s;
  }
}
